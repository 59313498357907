import { IonApp, IonToolbar, IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonRouterOutlet, IonSplitPane, IonTitle, setupIonicReact, IonItem } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { Redirect, Route } from 'react-router-dom';
import SidebarRight from './SidebarRight';
import SidebarLeft from './SidebarLeft';
import Home from './pages/Home';
import Zusatzstoffe from './pages/Zusatzstoffe';
import Woche from './pages/Woche';
import Fruehstueck from './pages/Fruehs';
import Nahrung from './pages/Nahrung';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import { useState } from 'react';
import Welcome from "./pages/Welcome"
import Mittag from "./pages/Mittag"
import Abend from "./pages/Abend"
import Stationen from "./pages/Stationen"
import Funktionen from "./pages/Funktionen"
import Bewohner from './pages/Bewohner';
import BewohnerIst from './pages/BewohnerIst';
import TagIst from './pages/TagIst';

import {DataContext} from './context/DataContext'
import AppContext from './context/AppContext';
import Stationsbedarf from './pages/Stationsbedarf';

setupIonicReact();

const App = () => {

  const [ appBewohnerZusatzstoffe , setAppBewohnerZusatzstoffe ] = useState( [] ) ;
  const [ appErfassung , setAppErfassung ] = useState( '' ) ;
  const [ appHaeuser , setAppHaeuser ] = useState( [] ) ;
  const [ appHaus , setAppHaus ] = useState( '' ) ;
  const [ appLoggedIn , setAppLoggedIn ] = useState( false ) ;

  /**
   * ! für Demo Wesel 12.2.25 "http://" als Default
   */
  // const [ appServer , setAppServer ] = useState( 'https://saint.ad.evkwesel.de/' ) ;
  const [ appServer , setAppServer ] = useState( 'http://saint.ad.evkwesel.de/' ) ;

  const [ appStationen , setAppStationen ] = useState( [] ) ;
  const [ appStationenMenues , setAppStationenMenues ] = useState( [] ) ;
  const [ appStation , setAppStation ] = useState( '' ) ;
  const [ appToken , setToken ] = useState( '' ) ;
  const [ appZusatzstoffe , setAppZusatzstoffe ] = useState( [] ) ;
  const [ appBewohnerId , setAppBewohnerId ] = useState( [] ) ;
  const [ appBewohnerSollId , setAppBewohnerSollId ] = useState( [] ) ;
  const [ appBewohnerSollBezeichnung , setAppBewohnerSollBezeichnung ] = useState( [] ) ;
  const [ appBewohnerIndex , setAppBewohnerIndex ] = useState( [] ) ;
  const [ appStationBezeichnung , setAppStationBezeichnung ] = useState(  ) ;
  const [ appStationsbedarf , setAppStationsbedarf ] = useState( [] ) ;
  const [ appStationsbedarf1 , setAppStationsbedarf1 ] = useState( true ) ;
  const [ appStationsbedarf2 , setAppStationsbedarf2 ] = useState( true );
  const [ appStationsbedarf3 , setAppStationsbedarf3 ] = useState( true ) ;
  const [ appStationsbedarf4 , setAppStationsbedarf4 ] = useState( true ) ;
  const [ appStationsbedarf5 , setAppStationsbedarf5 ] = useState( true ) ;
  const [ appStationsbedarf6 , setAppStationsbedarf6 ] = useState( true ) ;
  const [ appStationsbedarf7 , setAppStationsbedarf7 ] = useState( true ) ;
  const [ appStationsbedarfMinBestellDate , setAppStationsbedarfMinBestellDate ] = useState( [] ) ;
  const [ appStationsbedarfFilter , setAppStationsbedarfFilter ] = useState( [] ) ;
  const [ appStationsbedarfVorlauf , setAppStationsbedarfVorlauf ] = useState( 36 ) ;
  const [ appSollReloadAuswahl , setAppSollReloadAuswahl ] = useState(  ) ;

  let dataContextValue = { 
    appBewohnerZusatzstoffe: appBewohnerZusatzstoffe ,
    appBewohnerZusatzstoffeChange: setAppBewohnerZusatzstoffe ,
    appErfassung: appErfassung,
    appErfassungChange: setAppErfassung,
    appBewohnerId: appBewohnerId,
    appBewohnerIdChange: setAppBewohnerId,

    appBewohnerSollId: appBewohnerSollId,
    appBewohnerSollIdChange: setAppBewohnerSollId,
    appBewohnerSollBezeichnung: appBewohnerSollBezeichnung,
    appBewohnerSollBezeichnungChange: setAppBewohnerSollBezeichnung,
    appBewohnerIndex: appBewohnerIndex,
    appBewohnerIndexChange: setAppBewohnerIndex,
    appHaeuser: appHaeuser,
    appHaeuserChange: setAppHaeuser,
    appHaus: appHaus,
    appHausChange: setAppHaus,
    appLoggedIn: appLoggedIn,
    appLoggedInChange: setAppLoggedIn,
    appServer: appServer,
    appServerChange:setAppServer,
    appStationen: appStationen,
    appStationenChange: setAppStationen,
    appStation: appStation,
    appStationChange: setAppStation,
    appStationBezeichnung: appStationBezeichnung,
    appStationBezeichnungChange: setAppStationBezeichnung,
    appToken: appToken,
    appTokenChange: setToken,
    appZusatzstoffe: appZusatzstoffe ,
    appZusatzstoffeChange: setAppZusatzstoffe ,
    appStationenMenues: appStationenMenues ,
    appStationenMenuesChange: setAppStationenMenues ,
    appStationsbedarf:appStationsbedarf,
    appStationsbedarfChange:setAppStationsbedarf,
    appStationsbedarf1:appStationsbedarf1,
    appStationsbedarf1Change:setAppStationsbedarf1,
    appStationsbedarf2:appStationsbedarf2,
    appStationsbedarf2Change:setAppStationsbedarf2,
    appStationsbedarf3:appStationsbedarf3,
    appStationsbedarf3Change:setAppStationsbedarf3,
    appStationsbedarf4:appStationsbedarf4,
    appStationsbedarf4Change:setAppStationsbedarf4,
    appStationsbedarf5:appStationsbedarf5,
    appStationsbedarf5Change:setAppStationsbedarf5,
    appStationsbedarf6:appStationsbedarf6,
    appStationsbedarf6Change:setAppStationsbedarf6,
    appStationsbedarf7:appStationsbedarf7,
    appStationsbedarf7Change:setAppStationsbedarf7,
    appStationsbedarfFilter:appStationsbedarfFilter,
    appStationsbedarfFilterChange:setAppStationsbedarfFilter,
    appStationsbedarfVorlauf:appStationsbedarfVorlauf,
    appStationsbedarfVorlaufChange:setAppStationsbedarfVorlauf,
    appStationsbedarfMinBestellDate:appStationsbedarfMinBestellDate,
    appStationsbedarfMinBestellDateChange:setAppStationsbedarfMinBestellDate,
    appSollReloadAuswahl:appSollReloadAuswahl,
    appSollReloadAuswahlChange:setAppSollReloadAuswahl
  };

  const mk_content = ( show: 'login' | 'pane' ) =>
    {
      if( show === 'login' ) return <>{ mk_header() }<Welcome /></> ;
      return(
        <IonReactRouter>
          { mk_header() }
          <IonContent fullscreen>
            <IonSplitPane when="(min-width: 2024px)" contentId="main">
              <SidebarLeft />
              <IonPage id="main">
                <IonRouterOutlet>
                  <Route exact path="/">
                    <Home />
                  </Route>
                  <Route exact path="/home">
                    <Home />
                  </Route>
                  <Route exact path="/stationen/:hausid/:hausbezeichnung">
                    <Stationen />
                  </Route>
                  <Route exact path="/bewohner/:stationsid/:stationsbezeichnung">
                    <Bewohner />
                  </Route>
                  <Route exact path="/bewohnerist/:stationsid/:stationsbezeichnung">
                    <BewohnerIst />
                  </Route>
                  <Route exact path="/tag/:mahlzeit/:datum/:bewohnerid/:bewohnerbezeichnung">
                      <TagIst />
                  </Route>
                  <Route exact path="/funktionen/:stationsid/:stationsbezeichnung">
                    <Funktionen />
                  </Route>
                  <Route exact path="/stationsbedarf/:stationsid/:stationsbezeichnung">
                    <Stationsbedarf />
                  </Route>
                  <Route exact path="/woche/:stationsid/:stationsbezeichnung/:bewohnerid/:bewohnerbezeichnung">
                    <Woche />
                  </Route>
                  <Route exact path="/nahrung">
                      <Nahrung />
                  </Route>
                  <Route exact path="/abend/:stationsid/:stationsbezeichnung/:bewohnerid/:tag/:woche/:bewohnerbezeichnung/:datumsql">
                      <Abend />
                  </Route>
                  <Route exact path="/mittag/:stationsid/:stationsbezeichnung/:tag/:woche/:bewohnerid/:bewohnerbezeichnung/:datumsql">
                      <Mittag />
                  </Route>
                  <Route exact path="/fruehs/:stationsid/:stationsbezeichnung/:bewohnerid/:tag/:woche/:bewohnerbezeichnung/:datumsql">
                      <Fruehstueck />
                  </Route>
                  <Route exact path="/zusatzstoffe">
                      <Zusatzstoffe/>
                  </Route>
                </IonRouterOutlet>
                </IonPage>
              <SidebarRight/>
            </IonSplitPane>
          </IonContent>
        </IonReactRouter>
      ) ;
    }

  const mk_header = () =>
    {
      return (
        <IonHeader >
          <IonToolbar>
            <IonButtons slot="start">
              <IonMenuButton menu="start" auto-hide="false" color="primary"></IonMenuButton>
            </IonButtons>
            <IonItem
              button
              lines="none"
              routerLink="/home"
              routerDirection="back"
            >
              <IonTitle>Senioren Menüerfassung</IonTitle>
            </IonItem>
          </IonToolbar>
        </IonHeader>
      ) ;
    }

  return (
    <DataContext.Provider value={dataContextValue}>
      <AppContext />
      <IonApp>
        {
          !appLoggedIn ? mk_content( 'login' ) : mk_content( 'pane' )
        }
      </IonApp>
    </DataContext.Provider>
  );
};

export default App;
