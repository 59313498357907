import { IonList, IonItem, IonLabel, IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Qr.css';
import { useParams } from 'react-router';
import { useEffect, useState } from 'react';
import { Preferences } from '@capacitor/preferences';

import { useContext } from 'react';
import {DataContext} from '../context/DataContext'

export default function BewohnerIst() {
  const { appServer,appBewohnerId,appBewohnerIdChange,appBewohnerIndexChange } = useContext(DataContext);

  var date = new Date();
  var result = date.toISOString();
  var datumsql = result.slice(0,10)

  const [bewohner, setBewohner] = useState()
  const { stationsid } = useParams();
  const { stationsbezeichnung } = useParams();
  const [bewohnerliste, setBewohnerliste] = useState( <IonItem ><IonLabel>Lade Bewohner</IonLabel></IonItem> )

  function bewohnerlisteErstellen(){
    return(
      bewohner.map(
        ( elementInArray ,index) =>
          {
            if( elementInArray.betriebe_bewohner_wb === stationsid ) {
              return (        
                <IonItem
                  key = { 'bewohner_' + elementInArray.betriebe_bewohner_id }
                  button
                  routerLink =
                    {
                      "/tag/2/"
                      + datumsql
                      + "/"
                      + elementInArray.betriebe_bewohner_id
                      + '/'
                      + elementInArray.betriebe_bewohner_nachname + '_' + elementInArray.betriebe_bewohner_vorname
                    }
                    onClick =
                    {
                      () =>
                        {
                          // console.log('setze appBewohnerIdChange',elementInArray.betriebe_bewohner_id)
                          appBewohnerIdChange(elementInArray.betriebe_bewohner_id);
                          appBewohnerIndexChange(index);
                          
                        }
                    }
                >
                  <IonLabel>
                    {elementInArray.betriebe_bewohner_nachname}, {elementInArray.betriebe_bewohner_vorname} ( {elementInArray.betriebe_bewohner_raum} )
                  </IonLabel>
                </IonItem>
              )
            }
          }
      )
    )
  }
  
  useEffect(
    () =>
      {
        const checkBewohner = async () => {
          const { value } = await Preferences.get({ key: 'senioren.bewohner' })
          setBewohner(JSON.parse(value))
        }
        checkBewohner()
      },
    []
  ) ;
 
  useEffect(
    () =>
      {
        bewohner && setBewohnerliste( bewohnerlisteErstellen() ) 
        // console.log(bewohnerliste)
      },
    [ bewohner ]
  ) ;

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{stationsbezeichnung}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">{stationsbezeichnung}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          {bewohnerliste}
        </IonList>
      </IonContent>
    </IonPage>
  )
}
