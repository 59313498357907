import { IonContent, IonList, IonIcon, IonLabel, IonItem, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import './Home.css';
import readerIcon from '../assets/reader.svg'
import { useContext , useEffect, useState } from 'react' ;
import { DataContext } from '../context/DataContext' ;
import { Preferences } from '@capacitor/preferences';

export default function Funktionen() {
  const { appErfassungChange, appServer } = useContext(DataContext);

  const [stationen, setStationen] = useState([{betriebe_wb_ausblenden: "true",
  betriebe_wb_betriebe_id: "12",
  betriebe_wb_id: "30",
  betriebe_wb_name: "Lade Stationen"}])
  const [regServer, setRegServer] = useState( appServer );

  useEffect(
    () =>
      {
        const checkToken = async () => {
          const { value } = await Preferences.get({ key: 'senioren.token' })
          if (!value) { // console.log("checktoken", value); 
            return null }
          else return value;
        }
        checkToken()
          .then( () =>
            { 
              const checkServer = async () =>
                {
                  const { value } = await Preferences.get({ key: 'senioren.server' })
                  if (!value) {
                    // console.log("server", value);
                    return null ;
                  } else {
                    return value ;
                  }
              
                }
              checkServer()
                .then( result =>
                  {
                    // // console.log('server', result)
                    if (result !== null) {
                      setRegServer(result)
                    }
                  }
                )
            }
          )
      } ,
    []
  ) ;

  useEffect(() => {
    const sotrageStationen = async () => {
      // await window.localStorage.setItem("senioren.stationen",JSON.stringify(stationen))
      // console.log("setStationen");
    };
    sotrageStationen();
  }, [stationen]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Funktion</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">Funktion</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonList>
          <IonItem
            button
            routerLink = { `/stationen/x/x` }
            // routerLink = { `/bewohner/x/x` }
            onClick =
              {
                () =>
                  {
                    appErfassungChange( 'bewohner' ) ;
                  }
              }
          >
            <IonIcon slot="start" icon={readerIcon}></IonIcon>
            <IonLabel>
            Bestellung (SOLL-Erfassung)
          </IonLabel>
          </IonItem>
          <IonItem
            button
            routerLink = { `/stationen/x/x` }
            onClick =
              {
                () =>
                  {
                    appErfassungChange( 'bewohnerist' ) ;
                  }
              }
          >
            <IonIcon slot="start" icon={readerIcon}></IonIcon>
            <IonLabel>
            Verzehr (IST-Erfassung)
          </IonLabel>
          </IonItem>
          <IonItem
            button
            routerLink = { `/stationen/x/x` }
            onClick =
              {
                () =>
                  {
                    appErfassungChange( 'stationsbedarf' ) ;
                  }
              }
          >
            <IonIcon slot="start" icon={readerIcon}></IonIcon>
            <IonLabel>
            Stationsbedarf
          </IonLabel>
          </IonItem>
        </IonList>
      </IonContent>
    </IonPage>
  )
}
