/** React / Ionic */
import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';

/** Pages / Components */
import App from './App';

/** "Environment" für Capacitor SQLite ******************************************************************
 *  - gemäß
 *    - https://github.com/capacitor-community/sqlite/blob/master/docs/Web-Usage.md#ionicreact-app
 *    - https://github.com/capacitor-community/sqlite/blob/master/docs/Ionic-React-Usage.md#react-sqlite-hook-declaration-for-platforms-including-web
 */
import { defineCustomElements as jeepSqlite, JSX as LocalJSX } from 'jeep-sqlite/loader';
import { HTMLAttributes } from 'react';
import { Capacitor } from '@capacitor/core';
import { CapacitorSQLite, SQLiteConnection } from '@capacitor-community/sqlite';
type StencilToReact<T> = {
  [ P in keyof T ]?: T[ P ] &
  Omit<HTMLAttributes<Element>, 'className'> & {
    class?: string;
  };
};
declare global {
  export namespace JSX {
    interface IntrinsicElements extends StencilToReact<LocalJSX.IntrinsicElements> { }
  }
}
jeepSqlite( window );

/*******************************************************************************************************/

/** Funktionen */
/** initWithJeepSQLite
 * - zwecks Übersichtlichkeit augelagerte Callback-Funktion für "Execute" Capacitor SQLite
 * - vgl. https://github.com/capacitor-community/sqlite/blob/master/docs/Web-Usage.md#ionicreact-app
 */
const initWithJeepSQLite = async () => {
  const platform = Capacitor.getPlatform();
  const sqlite: SQLiteConnection = new SQLiteConnection( CapacitorSQLite );
  const run_react_mode = ( mode: number ) => {
    const mode_msg = () => {
      console.log( '! App wird von React 18 als React ' + mode + 'ausgeführt' );
    };
    if ( mode === 17 ) {
      mode_msg();
      ReactDOM.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>,
        document.getElementById( 'root' )
      );
      return;
    }
    if ( mode === 18 ) {
      mode_msg();
      const container = document.getElementById( 'root' );
      const root = createRoot( container! );
      root.render(
        <React.StrictMode>
          <App />
        </React.StrictMode>
      );
      return;
    }
    console.log( '! FEHLER: unbekannter React-18-Mode: ' + mode );
    return;
  };
  try {
    if ( platform === 'web' ) {
      const jeepEl = document.createElement( 'jeep-sqlite' );
      document.body.appendChild( jeepEl );
      await customElements.whenDefined( 'jeep-sqlite' );
      await sqlite.initWebStore();
    }

    /** ! Aktuelle DBInit-Implementierung läuft nur im React-17-Mode
     *  - => Optimierungsschritte von React 18 führen zu mehrfachen Ausführungen
     *    von Capacitor-sqlite-Operationen, dadurch explizte sqlite-Fehler und /
     *    oder versteckte Fehlfunktionen
     */
    run_react_mode( 17 );

    // serviceWorker.register();
  } catch ( err ) {
    // console.log(`Error initDB: ${err}`);
    console.error( 'initWithJeepSQLite Try/Catch-Fehler: ', err );
  }
};

/** Main */
/** "Execute" für Capacitor SQLite **********************************************************************
 *  - gemäß
 *    - https://github.com/capacitor-community/sqlite/blob/master/docs/Web-Usage.md#ionicreact-app
 *    - https://github.com/capacitor-community/sqlite/blob/master/docs/Ionic-React-Usage.md#react-sqlite-hook-declaration-for-platforms-including-web
 *  - Callback-Funktion ausgelagert für Übersichtlichkeit
 */
window.addEventListener( 'DOMContentLoaded', initWithJeepSQLite );
