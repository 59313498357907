import { FunctionComponent, useContext , useEffect, useState } from "react";
import { Preferences } from "@capacitor/preferences";
import { DataContext } from '../../context/DataContext'

type BewohnerZusatzstoffe_Props = {
  bewohnerid: Number
}

export const BewohnerZusatzstoffe: FunctionComponent<BewohnerZusatzstoffe_Props> = ( { bewohnerid } ) => {

  const { appBewohnerZusatzstoffe , appZusatzstoffe } = useContext( DataContext ) ;

  const [ bewohner , setBewohner ] = useState( { bewohner_zusatzstoffe: '' } ) ;

  const checkBewohner = async () => {
    const { value } = await Preferences.get( { key: 'senioren.bewohner' } ) ;
    // @ts-ignore
    let myObj = JSON.parse( value ) ;
    for( const[ key , value ] of Object.entries( myObj ) ) {
      // @ts-ignore
      if( value.betriebe_bewohner_id === bewohnerid ) {
        // @ts-ignore
        setBewohner( value ) ;
      }
    }                
  }

  useEffect(
    () => {
      checkBewohner() ;
    } ,
    [ ]
  ) ;

  return(
    <>
      {
        appBewohnerZusatzstoffe.length > 0
          ?
            <span style = { { color: 'red' } }>kein/e {
              appZusatzstoffe
                .filter(
                  ( entry:any ) => appBewohnerZusatzstoffe.includes( entry.zusatzstoff_id )
                )
                .map(
                  ( entry:any ) => entry.zusatzstoffe_anzeige + ') ' + entry.zusatzstoff_bezeichnung
                )
                .sort()
                .join( ', ' )
            }</span>
          :
            ''
      }
    </>
  )
}
