import {
  IonCard,
  IonButton,
  IonItem,
  IonList,
  IonGrid,
  IonCardHeader,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonSelect,
  IonSelectOption,
  useIonViewWillEnter,
  IonCardTitle,
  IonCardContent,
  IonCol,
  IonRow
} from "@ionic/react";
import "./Qr.css";
import { useParams } from "react-router";
import { Preferences } from "@capacitor/preferences";
import { BewohnerZusatzstoffe } from "../components/_funktionen/bewohner_zusatzstoffe";
import { RezeptZusatzstoffe } from '../components/_funktionen/rezept_zusatzstoffe';
import { Servierhinweise } from '../components/_funktionen/servierhinweise' ;
import { Doughnut} from 'react-chartjs-2';

import { useEffect, useState } from "react";
import axios from "axios";

import { useContext } from 'react';
import {DataContext} from '../context/DataContext'

export default function Order() {
  const [speiseplan, setSpeiseplan] = useState();
  const [viewMenue1, setViewMenue1] = useState();
  const [viewMenue2, setViewMenue2] = useState();
  const [viewMenue3, setViewMenue3] = useState();
  const [viewMenue4, setViewMenue4] = useState();
  const [bild1, setbild1] = useState("/bilder/kein_bild.png");
  const [bild2, setbild2] = useState("/bilder/kein_bild.png");
  const [bild3, setbild3] = useState("/bilder/kein_bild.png");
  const [bild4, setbild4] = useState("/bilder/kein_bild.png");
  const [viewNaehrwerte, setViewNaehrwerte] = useState();

  const [ bewohnerBemerkung , setBewohnerBemerkung ] = useState(  ) ;

  const checkBewohner = async () => {
    // console.log('checkBewohner',checkBewohner)
    const { value } = await Preferences.get( { key: 'senioren.bewohner' } ) ;
    // @ts-ignore
    let myObj = JSON.parse( value ) ;
    for( const[ , value ] of Object.entries( myObj ) ) {
      // @ts-ignore
      if( value.betriebe_bewohner_id === appBewohnerSollId ) {
        setBewohnerBemerkung( [ value.bewohner_bemerkung , value.bewohner_sonderkost ].join( ' ' ) ) ;
      }
    }                
  }

  // console.log("lade Mittag")
  const {
    appServer,
    appBewohnerSollIdChange,
    appBewohnerSollId,
    appBewohnerSollBezeichnung,
    appStation,
    appStationBezeichnung,
    appSollReloadAuswahlChange,
    appToken, 
  } = useContext(DataContext);

  const [naehrwerte, setNaehrwerte] = useState(
    {
      BE: 0,
EW: 0,
Fett: 0,
KH: 0,
Kcal: 0,
    }
  );

  const doughnutChartDataKcal = {
    labels: ['Gewählt', 'Tagesbedarf'],
    datasets: [
      {
        label: 'Kcal',
        backgroundColor: ['orange', 'green'],
        //borderColor: 'rgba(255,99,132,1)',
        borderWidth: 1,
        hoverBackgroundColor: 'rgba(255,99,132,0.4)',
        //hoverBorderColor: 'rgba(255,99,132,1)',
        data: [naehrwerte.Kcal, 1900-naehrwerte.Kcal]
      }
    ]
    };

      const doughnutChartDataEW = {
        labels: ['Kohlenhydrate', 'Eiweiß','Fett'],
        datasets: [
          {
            label: 'Kcal',
            backgroundColor: ['orange', 'green','red'],
            //borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
            //hoverBorderColor: 'rgba(255,99,132,1)',
            data: [naehrwerte.KH, naehrwerte.EW,naehrwerte.Fett]
          }
        ]
        };

  // const [menue1, setMenue1] = useState('0')
  // const [menue2, setMenue2] = useState('0')
  // const [menue3, setMenue3] = useState('0')
  // const [menue4, setMenue4] = useState('0')
  const [regServer, setRegServer] = useState(appServer);
  const [token, setToken] = useState();
  const [auswahl, setAuswahl] = useState([]);
  
  //const [gewaehlt, setGewaehlt] = useState();
  const [komponenten, setKomponenten] = useState([]);

  const { bewohnerid } = useParams();
  const { bewohnerbezeichnung, stationsbezeichnung, stationsid } = useParams();
  //const { woche } = useParams();
  const { datumsql } = useParams();
  var localdate = new Date(datumsql);

  useEffect(() => {
    // console.log("lade view neu");
    setViewMenue1(GenerateViewMenue("1"));
    setViewMenue2(GenerateViewMenue("2"));
    setViewMenue3(GenerateViewMenue("3"));
    setViewMenue4(GenerateViewMenue("4"));
    setViewNaehrwerte(GenerateViewNaehrwerte())
  }, [komponenten, speiseplan]);

  useEffect(() => {
    // console.log('ini effect')
  }, []);

  function addKomponente(rezept, newvalue) {
    let alt_komponenten = komponenten;
    alt_komponenten[rezept] = newvalue;
    setKomponenten(alt_komponenten);
    // console.log("alt_komponenten", alt_komponenten);
    // console.log("komponenten", komponenten);
    SendData(appBewohnerSollId, rezept, newvalue, 2, datumsql);
  }

  function SendData(bewohner_id, rezept, menge, zeit, datum) {
    // console.log("regServer", regServer);
    // console.log("bewohner_id", bewohner_id);
    // console.log("rezept", rezept);
    // console.log("menge", menge);
    // console.log("zeit", zeit);
    // console.log("datum", datum);

    // const fetchData = {
    //   token: token,
    //   //BetriebeId: BetriebeId,
    // };
    // const api = axios.create({
    //   baseURL:
    //     regServer +
    //     "/funktionen/api2/senioren/rezept_buchen.php?bewohner_id=" +
    //     bewohner_id +
    //     "&rezept=" +
    //     rezept +
    //     "&menge=" +
    //     menge +
    //     "&zeit=" +
    //     zeit +
    //     "&datum=" +
    //     datum,
    // });
    // api.post("", fetchData).then((res) => {
    //   // console.log("daten", data);
    // });

    const config = {
      method: 'post',
      url:
        appServer +
        '/funktionen/api2/senioren/rezept_buchen.php?bewohner_id='+bewohner_id+'&rezept='+rezept+'&menge='+menge+'&zeit='+zeit+'&datum='+datumsql,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ appToken }`
      }
    };
    axios( config )
    .then( ( response ) => {
    } )
    .catch( () => console.error( 'FEHLER:', 'Fehler beim Buchen (Code 25feb05-1044' ) );

  }

  useEffect(() => {
    // console.log("aktualisiere");
    //setKomponenten([]);
    let alt_komponenten = [];
    auswahl &&
      auswahl.map((gewaehltInArray) => {
        if (gewaehltInArray.bewohner_verzehr_zeit === "2") {
          // console.log( "gewaehltInArray.bewohner_verzehr_menge", gewaehltInArray.bewohner_verzehr_menge );
          if (gewaehltInArray.bewohner_verzehr_tag === datumsql)
            alt_komponenten[gewaehltInArray.rezepte_id] =
              gewaehltInArray.bewohner_verzehr_menge;
          // console.log("alt_komponenten", alt_komponenten);
        }

        return true;
      });
    setKomponenten(alt_komponenten);
    // console.log("gesetzte alt_komponenten", alt_komponenten);
    //setSpeiseplanreturn(speiseplan())
    // console.log("auswahl change neuladen");
    setViewMenue1(GenerateViewMenue("1"));
    setViewMenue2(GenerateViewMenue("2"));
    setViewMenue3(GenerateViewMenue("3"));
    //setViewMenue4(GenerateViewMenue("4"));
    setViewNaehrwerte(GenerateViewNaehrwerte())
  }, [auswahl]);

  function GenerateViewMenue(kategorie) {
    // console.log("generiere view", kategorie);
    return (
      speiseplan &&
      speiseplan.map((elementInArray) => {
        if(elementInArray.speiseplan_tag === datumsql &&
          elementInArray.speiseplan_kategorie === kategorie &&
          elementInArray.betriebe_speiseplaene_zeit === "2"){
        if(kategorie==='1'){
          setbild1("konfig/evkw/bilder/rezepte/"+elementInArray.tag_bild)
          // console.log('bild1',elementInArray.tag_bild)
        }
        if(kategorie==='2'){
          setbild2("konfig/evkw/bilder/rezepte/"+elementInArray.tag_bild)
          // console.log('bild2',elementInArray.tag_bild)

        }
        if(kategorie==='3'){
          setbild3("konfig/evkw/bilder/rezepte/"+elementInArray.tag_bild)
          // console.log('bild3',elementInArray.tag_bild)

        }
        // if(kategorie==='4'){
        //   setbild4("bilder/ofw/rezepte/"+elementInArray.tag_bild)
        //   // console.log('bild4',elementInArray.tag_bild)

        // }
      }
        return (
            
          <>
            {elementInArray.speiseplan_tag === datumsql &&
            elementInArray.speiseplan_kategorie === kategorie &&
            elementInArray.betriebe_speiseplaene_zeit === "2" ? (
              <>
                <IonItem slot="start" >
                  {elementInArray.rezepte_bezeichnung +
                    " (" +
                    elementInArray.ausgabemenge +
                    "g)"}
                  <br />
                  {elementInArray.naehrwerte}
                  <RezeptZusatzstoffe rezepte_zusatzstoffe = {elementInArray.rezepte_zusatzstoffe} />                 
                </IonItem>
                <IonItem slot="end" color={komponenten[elementInArray.rezepte_id]>0
                        ? "success"
                        : ""}>
                  Portion:
                  <IonSelect 
                    onIonChange={(e) => {
                      // console.log("geändert", e);
                      addKomponente(
                        elementInArray.rezepte_id,
                        e.currentTarget.value
                      );
                      appSollReloadAuswahlChange(true);
                    }}
                    
                    size="large"
                    
                    value={
                      komponenten[elementInArray.rezepte_id]
                        ? komponenten[elementInArray.rezepte_id]
                        : "0"
                    }
                    
                    ok-text="speichern"
                    cancel-text="abbrechen"
                  >
                    <IonSelectOption value="0">0</IonSelectOption>
                    <IonSelectOption value="1">1</IonSelectOption>
                    <IonSelectOption value="0.75">3/4</IonSelectOption>
                    <IonSelectOption value="0.5">1/2</IonSelectOption>
                    <IonSelectOption value="0.25">1/4</IonSelectOption>
                  </IonSelect>
                </IonItem>
              </>
            ) : (
              ""
            )}
          </>
        );
      })
    );
  }

  function GenerateViewNaehrwerte(){
    // console.log("generiere Naehrwerte");
    var newNaehrwerte={
      BE:0,
      EW: 0,
      Fett: 0,
      KH: 0,
      Kcal: 0,
    }
    speiseplan &&
      speiseplan.map((elementInArray) => {
        if(elementInArray.speiseplan_tag === datumsql &&
            elementInArray.betriebe_speiseplaene_zeit === "2" ){
        let newBE=newNaehrwerte.BE+parseFloat(komponenten[elementInArray.rezepte_id]?komponenten[elementInArray.rezepte_id]:0)*parseFloat(elementInArray.naehrwerte_array.BE)
        let newEW=newNaehrwerte.EW+parseFloat(komponenten[elementInArray.rezepte_id]?komponenten[elementInArray.rezepte_id]:0)*parseFloat(elementInArray.naehrwerte_array.EW)
        let newFett=newNaehrwerte.Fett+parseFloat(komponenten[elementInArray.rezepte_id]?komponenten[elementInArray.rezepte_id]:0)*parseFloat(elementInArray.naehrwerte_array.Fett)
        let newKcal=newNaehrwerte.Kcal+parseFloat(komponenten[elementInArray.rezepte_id]?komponenten[elementInArray.rezepte_id]:0)*parseFloat(elementInArray.naehrwerte_array.Kcal)
        let newKH=newNaehrwerte.KH+parseFloat(komponenten[elementInArray.rezepte_id]?komponenten[elementInArray.rezepte_id]:0)*parseFloat(elementInArray.naehrwerte_array.KH)
        // console.log('newBE',newBE) 
        newNaehrwerte={
          BE: newBE,
          EW: newEW,
          Fett: newFett,
          KH: newKH,
          Kcal: newKcal,
        }
        // console.log('newNaehrwerte',newNaehrwerte)
        setNaehrwerte(
          newNaehrwerte
        )
        }
        }
        
        )
  }

  useIonViewWillEnter(() => {
    
    // console.log("initialisiere daten");
    const checkToken = async () => {
      const { value } = await Preferences.get({ key: "senioren.token" });
      if (!value) {
        // console.log("checktoken", value);
        return null;
      } else return value;
    };

    checkToken().then(() => {
      // // console.log("checktokenresult", result)

      const checkServer = async () => {
        const { value } = await Preferences.get({ key: "senioren.server" });
        if (!value) {
          // console.log("server", value);
          return null;
        } else return value;
      };
      checkServer().then((result) => {
        // console.log("server", result);
        if (result !== null) {
          setRegServer(result);
        }
      });
      const checkSpeiseplan = async () => {
        const { value } = await Preferences.get({ key: "senioren.mittag" });
        if (!value) {
          // console.log("checktoken", value);
          return null;
        } else return value;
      };

      checkSpeiseplan().then((result) => {
        // console.log("mittag", JSON.parse(result));
        if (result !== null) {
          setSpeiseplan(JSON.parse(result));
        }
      });

      const checkAuswahl = async () => {
        const { value } = await Preferences.get({ key: "senioren.auswahl" });
        if (!value) {
          // console.log("checktoken", value);
          return null;
        } else return value;
      };

      checkAuswahl().then((result) => {
        // console.log("auswahl", JSON.parse(result));
        if (result !== null) {
          setAuswahl(JSON.parse(result));
        }
      });
    });
    checkBewohner()
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            Mittag {appBewohnerSollBezeichnung} - {localdate.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
          </IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">
              Mittag {appBewohnerSollBezeichnung} - {localdate.toLocaleDateString('de-DE', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            </IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonButton
          routerLink={
            "/woche/" +
            appStation +
            "/" +
            appStationBezeichnung +
            "/" +
            appBewohnerSollId +
            "/" +
            appBewohnerSollBezeichnung
          }
          onClick =
                      {
                        () =>
                          {

                            appBewohnerSollIdChange(appBewohnerSollId);
                            appSollReloadAuswahlChange(true);

                          }
                      }
        >
          speichern & zurück 
        </IonButton>
        {
          //woche == '2' ? '': <IonItem>Es kann nicht mehr bestellt werden</IonItem>
        }
        <IonItem>
          <Servierhinweise appBewohnerSollId = { appBewohnerSollId } zeit = "Mittag" />
          <BewohnerZusatzstoffe appBewohnerSollId = { appBewohnerSollId } />
          {bewohnerBemerkung}
        </IonItem>

        <IonCard class="ion-text-center">
          <IonCardHeader></IonCardHeader>
          <IonGrid>
            <ion-row>
              <ion-col>
                <img
                  src={regServer + "/"+bild1}
                  alt="kein Bild vorhanden"
                ></img>

                <IonList>{viewMenue1 && viewMenue1}</IonList>
              </ion-col>
              <ion-col>
                <img
                  src={regServer + "/"+bild2}
                  alt="kein Bild vorhanden"
                ></img>

                <IonList>{viewMenue2 && viewMenue2}</IonList>
              </ion-col>
              <ion-col>
                <img
                  src={regServer + "/"+bild3}
                  alt="kein Bild vorhanden"
                ></img>

                <IonList>{viewMenue3 && viewMenue3}</IonList>
              </ion-col>
              {/* <ion-col>
                <img
                  src={regServer + "/"+bild4}
                  alt="kein Bild vorhanden"
                ></img>

                <IonList>{viewMenue4 && viewMenue4}</IonList>
              </ion-col> */}
                
            </ion-row>
          </IonGrid>
        </IonCard>
        <IonCard>
          <IonCardHeader>
          <IonCardTitle>Nährwerte</IonCardTitle>
          </IonCardHeader>
          <IonCardContent>
         
    <IonGrid>
        <IonRow>
          <IonCol>
    <IonCard>
      <IonCardHeader>
        <IonCardTitle>Kcal</IonCardTitle>
      </IonCardHeader>
    <Doughnut data={doughnutChartDataKcal}
                options={{ maintainAspectRatio: true}}   />
    </IonCard></IonCol>
    <IonCol>
    <IonCard>
    <IonCardHeader>
        <IonCardTitle>Zusammensetzung</IonCardTitle>
      </IonCardHeader>
    <Doughnut data={doughnutChartDataEW}
                options={{ maintainAspectRatio: true}}   />
    </IonCard></IonCol>

        </IonRow>

    </IonGrid>
    <IonItem>BE {Intl.NumberFormat('de-DE',{maximumSignificantDigits: 2}).format(naehrwerte.BE)}</IonItem>
    <IonItem>Kcal {naehrwerte.Kcal}</IonItem>
    <IonItem>Kohlenhydrate {naehrwerte.KH}g</IonItem>
    <IonItem>Fett {naehrwerte.Fett}g</IonItem>
    <IonItem>Eiweiß {naehrwerte.EW}g</IonItem>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
}
