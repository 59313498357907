import { FunctionComponent, useEffect, useState } from "react";
import { Preferences } from "@capacitor/preferences";

type Servierhinweise_Props = {
  bewohnerid: Number ,
  zeit: string
}

export const Servierhinweise: FunctionComponent<Servierhinweise_Props> = ( { bewohnerid , zeit } ) => {

  const [ bewohner , setBewohner ] = useState( {} ) ;
  const [ hints , setHints ] = useState( [] ) ;
  const [ bemerkung , setBemerkung ] = useState( "" ) ;

  const checkBewohner = async () => {
    const { value } = await Preferences.get( { key: 'senioren.bewohner' } ) ;
    // @ts-ignore
    let myObj = JSON.parse( value ) ;
    for( const[ key , value ] of Object.entries( myObj ) ) {
      // @ts-ignore
      if( value.betriebe_bewohner_id === bewohnerid ) {
        // @ts-ignore
        setBewohner( value ) ;

      }
    }                
  }

  


  const checkHints = ( zeit:any ) => {
    let hintFields = [
      [ 'Ohne Rinde' , 'Früh' , 'rinde' ] , 
      [ 'Geschmiert' , 'Früh' , 'geschmiert' ] , 
      [ 'Mundgerecht' , 'Früh' , 'mundgerecht' ] , 
      [ 'Hochkalorisch' , 'Früh' , 'hochkalorisch_frueh' ] , 
      [ 'Passiert' , 'Früh' , 'passiert_frueh' ] , 
      [ 'Hochkalorisch' , 'Mittag' , 'hochkalorisch' ] , 
      [ 'Mundgerecht' , 'Mittag' , 'mundgerecht_mittag' ] , 
      [ 'Passiert' , 'Mittag' , 'passiert' ] , 
      [ 'Ohne Rinde' , 'Abend' , 'rinde_abend' ] , 
      [ 'Geschmiert' , 'Abend' , 'geschmiert_abend' ] , 
      [ 'Mundgerecht' , 'Abend' , 'mundgerecht_abend' ] , 
      [ 'Hochkalorisch' , 'Abend' , 'hochkalorisch_abend' ] ,
      [ 'Passiert' , 'Abend' , 'passiert_abend' ],
    ] ;
    setHints (
      // @ts-ignore
      hintFields
        .filter(
          // @ts-ignore
          entry => entry[ 1 ] === zeit && bewohner[ 'bewohner_' + entry[ 2 ] ] === '1'          
        )
        .map(
          entry => entry[ 0 ]
        )
    );

    
  }

  useEffect(
    () => {
      checkBewohner() ;
    } ,
    [ ]
  ) ;

  useEffect(
    () => {
      checkHints( zeit ) ;
      
    } ,
    [ bewohner ]
  ) ;

  return(
    <>
      { hints.join( ', ' ) }
    </>
  )
}

