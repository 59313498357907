import { Preferences } from "@capacitor/preferences";

export const logout = async () => {
    await Preferences.remove({ key: "senioren.token" });
    await Preferences.remove({ key: "senioren.abend" });
    await Preferences.remove({ key: "senioren.auswahl" });
    await Preferences.remove({ key: "senioren.bewohner" });
    await Preferences.remove({ key: "senioren.frueh" });
    await Preferences.remove({ key: "senioren.mittag" });

    // let items_localStorage = ["bewohner", "haeuser", "rezepte", "server", "speiseplan", "stationen", "token"];
    // items_localStorage.forEach((item: string) => {
    //   console.log("localStorage.removeItem: senioren." + item);
    //   localStorage.removeItem('senioren.' + item);
    // });

    window.location.reload() ;

  };
