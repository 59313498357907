import { IonCard, IonIcon, IonTitle, IonToast, IonFooter, IonItem, IonLabel, IonMenu, IonToolbar, setupIonicReact, IonMenuToggle } from "@ionic/react";
import { IonContent } from "@ionic/react";
import { useEffect, useState } from "react";

import homeIcon from "./assets/home.svg";
import "./App.css";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/* Theme variables */
import "./theme/variables.css";

import readerIcon from "./assets/reader.svg";
import returnIcon from "./assets/return.svg";

import { Preferences } from "@capacitor/preferences";

import { useContext } from "react";
import { DataContext } from "./context/DataContext";
import { logout } from "./functions/logout";

setupIonicReact();

export default function SidebarLeft() {
  const [toast, setToast] = useState(false);
  const [difference, setDifference] = useState(null);
  const version = "1.0.2";

  return (
    <IonMenu side="start" contentId="main" type="overlay">
      <IonContent>
        <IonItem color="secondary"></IonItem>
        <IonItem lines="none">
          <IonCard id="cfmcard">
            <img src="https://saint-online.de/wp-content/uploads/2019/10/saint-logo20190212-1.png" alt="CFM LOGO" id="cfm" />
          </IonCard>
        </IonItem>
        <IonMenuToggle>
          <IonItem button routerLink="/home" routerDirection="back">
            <IonIcon slot="start" icon={homeIcon}></IonIcon>
            <IonLabel>
              {/* <Link to="/home">Startseite</Link> */}
              Kostenstelle wählen
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
        <IonMenuToggle>
          <IonItem button routerLink={ 'Zusatzstoffe' } routerDirection="back">
            <IonIcon slot="start" icon={readerIcon}></IonIcon>
            <IonLabel>
              Zusatzstoffe
            </IonLabel>
          </IonItem>
        </IonMenuToggle>
        <IonItem
          onClick={() => {
            logout();
          }}
          button
          routerLink="/"
        >
          <IonIcon slot="start" color="danger" icon={returnIcon}></IonIcon>
          <IonLabel>Abmelden</IonLabel>
        </IonItem>
        <IonToast
          isOpen={toast}
          color="dark"
          onDidDismiss={() => setToast(false)}
          message={`Für diese App ist ein Update verfügbar ( ${version} -> ${difference} )`}
          duration={2000}
        />
      </IonContent>
      <IonFooter>
        <IonToolbar>
          <IonTitle>
            <h6>version {version}</h6>
          </IonTitle>
        </IonToolbar>
      </IonFooter>
    </IonMenu>
  );
}
