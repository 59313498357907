import { IonSelect, IonSelectOption,IonSegment,IonSegmentButton,IonList, IonLabel, IonButton,IonInput, IonItem,IonGrid,IonRow,IonCol,IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonDatetime, useIonViewDidEnter } from '@ionic/react';
import './Qr.css';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import axios from 'axios';
import { Preferences } from '@capacitor/preferences';
import { useContext } from 'react';
import {DataContext} from '../context/DataContext'

export default function TagIst() {
  const { appToken,appServer,appBewohnerId,appBewohnerIdChange,appBewohnerIndex,appBewohnerIndexChange } = useContext(DataContext);
  const { bewohnerid } = useParams();
  const { bewohnerbezeichnung } = useParams();
  const { datum } = useParams();
  const { datumsql } = useParams();
  const { mahlzeit } = useParams();
  const [zeit, setZeit] = useState(mahlzeit);
  const [NextBewohnerLink, setNextBewohnerLink] = useState()
  const [PrevBewohnerLink, setPrevBewohnerLink] = useState()
  const [NextBewohnerId, setNextBewohnerId] = useState()
  const [PrevBewohnerId, setPrevBewohnerId] = useState()
  const [gewicht, setGewicht] = useState(90)
  const [groesse, setGroesse] = useState(165)
  const [bmi, setBMI] = useState(0)
  const [bmifarbe, setBMIFarbe] = useState("warning")
  const [token, setToken] = useState(appToken)
  const [regServer, setRegServer] = useState(appServer);
  const [komponenten, setKomponenten] = useState([])
  const [gewaehlt, setGewaehlt] = useState();
  const [datumPicker, setDatumPicker] = useState(datum);
  const [datumPickerNext, setDatumPickerNext] = useState();
  const [datumPickerPrev, setDatumPickerPrev] = useState();
  const [bewindex, setBewindex] = useState(appBewohnerIndex);
  const [BewohnerWahlReturn, setBewohnerWahlReturn] = useState(<IonRow><IonItem>Lade</IonItem></IonRow>);
  // const [auswahl, setAuswahl] = useState(json)
  const [auswahl, setAuswahl] = useState( [ ] )
  const [speiseplanreturn, setSpeiseplanreturn] = useState()

  const checkAuswahl = async () => {
    // console.log('checkAuswahl')
    let essen ='frueh'
    if( zeit === '1' ) {
      essen='frueh'
    }
    else if( zeit === '2') {
      essen='mittag'
    }
    else {
      essen='abend'
    }
    const { value } = await Preferences.get( { key: 'senioren.' + essen } )
    setAuswahl(JSON.parse(value))
  }

  const checkGewaehlt = async () => {
    // console.log('checkGewaehlt')
    const getDataAuswahl = () => {
      var myHeaders = new Headers();
      myHeaders.append(
        "Content-Type",
        "application/x-www-form-urlencoded; charset=UTF-8"
      );
      myHeaders.append("Authorization", "Bearer " + token);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
      };
      fetch(regServer + "/funktionen/api2/senioren/auswahl.php?bewhohner_id="+bewohnerid, requestOptions)
      .then((response) => response.json())
      .then(async (result) => {
        setAuswahl(JSON.stringify(result))
        // console.log("result", result);
        let storagedata = {
          key: "senioren.auswahl",
          value: JSON.stringify(result),
        };
        await Preferences.set(storagedata);
      });
    }
    getDataAuswahl()
    const { value } = await Preferences.get({ key: 'senioren.auswahl' })
    setGewaehlt(JSON.parse(value))
  }

  function SendData(bewohner_id, rezept, menge, zeit, datum){
    const fetchData = {
      token: token,
      //BetriebeId: BetriebeId,
    };
    const api = axios.create(
      {
        baseURL: regServer+'/funktionen/api2/senioren/rezept_buchen_verzehr.php?bewohner_id='+bewohner_id+'&rezept='+rezept+'&menge='+menge+'&zeit='+zeit+'&datum='+datumsql,
      }
    );
    api.post( "" , fetchData )
      .then(
          ( res ) =>
            {
              let data = res.data;
            }
        )
  }

  function speiseplan() {
    // console.log("function speiseplan gestartet")
    // console.log("function speiseplan gestartet auswahl",auswahl)
    // console.log("function speiseplan gestartet datumPicker",datumPicker)
    // console.log("function speiseplan gestartet zeit",zeit)
    // console.log("function speiseplan gestartet komponenten",komponenten)

    if(  Array.isArray( auswahl   ) ){
      return (
        auswahl
          .map(
            ( elementInArray, index ) =>
              {
                if( elementInArray.speiseplan_tag == datumPicker && zeit === '2' ) {
                  return artikel(elementInArray, index)
                }
                else if(zeit==='1'){
                  return artikel(elementInArray, index)
                }
                else if(zeit==='3'){

                  return artikel(elementInArray, index)
                }
              } 
          )
      )
    }
    
  }

  function artikel(elementInArray, index){
    let color=""
    if(komponenten[elementInArray.speiseplan_rezepte_id]>0){
      color="success"
    }
    // console.log('komponenten[elementInArray.speiseplan_rezepte_id]',komponenten[elementInArray.speiseplan_rezepte_id])
    return(
      <> 
        <IonItem  color={color}>
          <IonLabel>
            {komponenten[elementInArray.speiseplan_rezepte_id]} {elementInArray.rezepte_bezeichnung} 
          </IonLabel>
          <IonItem>
            <IonSelect
              onIonChange={(e) => {
                addKomponente(
                  elementInArray.rezepte_id,
                  e.currentTarget.value
                );
              }}
              size="large"
              value={komponenten[elementInArray.speiseplan_rezepte_id] ? komponenten[elementInArray.speiseplan_rezepte_id]  : "0"}
              ok-text="speichern"
              cancel-text="abbrechen"
            >
              <IonSelectOption key="val0" value="0">0</IonSelectOption>
              <IonSelectOption key="val2" value="2">2</IonSelectOption>
              <IonSelectOption key="val_175" value="1.75">1 3/4</IonSelectOption>
              <IonSelectOption key="val1_5" value="1.5">1 1/2</IonSelectOption>
              <IonSelectOption key="val1_25" value="1.25">1 1/4</IonSelectOption>
              <IonSelectOption key="val1" value="1">1</IonSelectOption>
              <IonSelectOption key="val0_75" value="0.75">3/4</IonSelectOption>
              <IonSelectOption key="val0_5" value="0.5">1/2</IonSelectOption>
              <IonSelectOption key="val0_25" value="0.25">1/4</IonSelectOption>
            </IonSelect>
          </IonItem>
        </IonItem>
      </>
    )
  }

  function BewohnerWahlErzeugen(){
    // console.log('BewohnerWahlErzeugen')
    return(
      <> 
       <IonRow>
            <IonCol>
              {
                appBewohnerIndex === 0
                  ?
                    ""
                  :
                    <IonButton float-left routerLink={PrevBewohnerLink}
                    onClick =
                    {
                      () =>
                        {
                          // console.log('setze appBewohnerId',PrevBewohnerId)
                          appBewohnerIdChange(PrevBewohnerId);
                          setBewindex(appBewohnerIndex-1)
                          appBewohnerIndexChange(appBewohnerIndex-1)
                        }
                    }
                    >
                      zurück
                    </IonButton>
              }
            </IonCol>
            <IonCol>
              {bewohnerbezeichnung}
              </IonCol>
              <IonCol>
                <IonButton float-right 
                
                routerLink={NextBewohnerLink}
                onClick =
                    {
                      () =>
                        {
                          // console.log('setze NextBewohnerId',NextBewohnerId)
                          appBewohnerIdChange(NextBewohnerId);
                          setBewindex(appBewohnerIndex+1)
                          appBewohnerIndexChange(appBewohnerIndex+1)
                          
                        }
                    }
                >
                    weiter
                </IonButton>
            </IonCol>
          </IonRow>
      </>
    )
  }

  function addKomponente(rezept,newvalue){
    // console.log("function addKomponente fired")

    let alt_komponenten=komponenten
    alt_komponenten[rezept]=newvalue
    setKomponenten(alt_komponenten)
    //SendData(bewohnerid,rezept,newvalue, zeit, datumsql)
    setSpeiseplanreturn(speiseplan()) 
  }
  
  useEffect(
    () =>
      {
        // console.log("useeffect gewahlt gestartet")
        let alt_komponenten=komponenten;
        gewaehlt && gewaehlt.map( (gewaehltInArray) =>
          {
            if(gewaehltInArray.bewohner_verzehr_zeit === zeit ){
              alt_komponenten[gewaehltInArray.rezepte_id]=gewaehltInArray.bewohner_verzehr_menge
            }
          }
        )
        setKomponenten(alt_komponenten)
        
        setSpeiseplanreturn(speiseplan())
      } ,
    [gewaehlt]
  ) ; 
  
  useEffect(
    () =>
      {
        //checkGewaehlt()

        checkAuswahl()
        checkGewaehlt()
        var heute = new Date(datumPicker);
        // Gibt die vergangene Zeit bis zum 1.1.1970 in ms zurück
        var tagZeit = 1000 * 60 * 60 * 26; // 1000 ist 1s * 60 = 1min * 60 = 1std * 24 = 1Tag
        var zeitGestern = heute-tagZeit;
        var zeitMorgen = zeitGestern+parseInt(2*tagZeit);
        var morgen = new Date(zeitMorgen);
        let return_day=morgen.toLocaleDateString( "en-GB",
          {             
            day: "2-digit"
          }
        );
        let return_month=morgen.toLocaleDateString("en-GB",
          {
             month: "2-digit"
          }
        );
        let return_year=morgen.toLocaleDateString("en-GB",
          {
            year: "numeric"
          }
        );
        setDatumPickerNext(return_year+'-'+return_month+'-'+return_day)
        var gestern = new Date(zeitGestern);
        let g_return_day=gestern.toLocaleDateString("en-GB",
          {
            day: "2-digit"
          }
        );
        let g_return_month=gestern.toLocaleDateString("en-GB",
          {
            month: "2-digit"
          }
        );
        let g_return_year=gestern.toLocaleDateString("en-GB",
          {
            year: "numeric"
          }
        );
        setDatumPickerPrev(g_return_year+'-'+g_return_month+'-'+g_return_day)
      } ,
    [ zeit , datumPicker ]
  ) 
 
  useEffect(
    () =>
      {
        // console.log("useeffect auswahl fired")
        //setSpeiseplanreturn(speiseplan())
      } ,
    [ auswahl ]
  )  
 
  useEffect(
    () =>
      {
        let bmi = Math.round(gewicht / ((groesse/100)*(groesse/100)))
        setBMI(bmi)
        if(bmi < 19){
          setBMIFarbe('warning')
        }
        else if (bmi >= 19 && bmi <= 25){
          setBMIFarbe('success')
        }
        else {
          setBMIFarbe('warning')
        }
      } ,
    [ groesse , gewicht ]
  )

  useIonViewDidEnter( () =>
    {
      // console.log('useIonViewDidEnter fired')
      // console.log('Hole Token')
      const checkToken = async () =>
        {
          const { value } = await Preferences.get({ key: 'senioren.token' })
          if (!value) { return null }
          else return value;
        }
      checkToken()
        .then(() =>
          { 
            // console.log('hole server')
            const checkServer = async () =>
              {
                const { value } = await Preferences.get({ key: 'senioren.server' })
                if (!value) { return null }
                else return value;
              }
            checkServer()
              .then( result =>
                {
                  if (result !== null) {
                    setRegServer(result)
                  }
                }
              )
              .then(result => {
                checkBewohner()
                })
              .then(result => {
                checkAuswahl()
                
              })
              .then(result => {
                
                checkGewaehlt()
              })
              .then(result => {
                
                //setSpeiseplanreturn(speiseplan()) 
              })
          }
        )
      
      const checkBewohner = async () =>
        {
          // console.log('checkBewohner')
          const {value}  = await Preferences.get({ key: 'senioren.bewohner' })
          JSON.parse(value).map((elementInArray, index) =>
            {
              //prev_name=elementInArray.bewohnerbezeichnung;
              if (elementInArray.betriebe_bewohner_id == appBewohnerId){
                // console.log('setBewindex',index)
                setBewindex(index)
                appBewohnerIndexChange(index)
                // console.log('Gewicht',elementInArray.betriebe_bewohner_gewicht)
                // console.log('Groesse',elementInArray.betriebe_bewohner_groesse)
                setGewicht(elementInArray.betriebe_bewohner_gewicht)
                setGroesse(elementInArray.betriebe_bewohner_groesse)
              }
            }
          )
        }
      checkBewohner()
      
    }
  )

  useEffect(
    () =>
      {
        // console.log('useeffect bewindex gefeuert',bewindex)
        var newNextBewohnerLink=""
        var newPrevBewohnerLink=""
        var newNextBewohnerId=0
        var newPrevBewohnerId=0
        const checkBewohnerNext = async () =>
          {
            const {value}  = await Preferences.get({ key: 'senioren.bewohner' })
            JSON.parse(value)
              .map(
                (elementInArray, index) =>
                  {
                    //prev_name=elementInArray.bewohnerbezeichnung;
                    if (index === bewindex-1){
                      newPrevBewohnerLink="/tag/"+zeit+"/"+datum+"/"+elementInArray.betriebe_bewohner_id+'/'+elementInArray.betriebe_bewohner_nachname + '_' + elementInArray.betriebe_bewohner_vorname
                      newPrevBewohnerId=elementInArray.betriebe_bewohner_id
                    }
                    if (index === bewindex+1){
                      newNextBewohnerLink="/tag/"+zeit+"/"+datum+"/"+elementInArray.betriebe_bewohner_id+'/'+elementInArray.betriebe_bewohner_nachname + '_' + elementInArray.betriebe_bewohner_vorname
                      newNextBewohnerId=elementInArray.betriebe_bewohner_id

                    }
                  }
              )
              setNextBewohnerLink(newNextBewohnerLink)
              setPrevBewohnerLink(newPrevBewohnerLink)
              setNextBewohnerId(newNextBewohnerId)
              setPrevBewohnerId(newPrevBewohnerId)
          }
        checkBewohnerNext()
        .then(
          
          setBewohnerWahlReturn(BewohnerWahlErzeugen()) 
        )
      } ,
    [bewindex]
  ) 

  /* useEffect(
    () =>
      {
        // console.log( 'useeffect bewohnerid:' , bewohnerid ) ;
        checkGewaehlt()
      } ,
    [ bewohnerid ]
  )  */

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{bewohnerbezeichnung}</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader>
          <IonToolbar>
            <IonTitle size="large">{bewohnerbezeichnung}</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton float-left  onClick={() => setDatumPicker(datumPickerPrev)} >zurück</IonButton>
            </IonCol>
            <IonCol>
              <IonItem>
                <IonInput value= {datumPicker } id="date">
                </IonInput>
                <ion-popover trigger="date" size="cover">
                  <IonDatetime  
                    presentation = "date"
                    locale = "de-De"
                    value = { datumPicker }
                    onIonChange={e=>setDatumPicker(e.target.value)}
                  ></IonDatetime>
                </ion-popover>
              </IonItem>
            </IonCol>
            <IonCol>
              <IonButton float-right   onClick={() => setDatumPicker(datumPickerNext)}>weiter</IonButton>
            </IonCol>
          </IonRow>
          {BewohnerWahlReturn && BewohnerWahlReturn}
          
        </IonGrid>
        <IonSegment value={zeit} onClick={(e) => setZeit(e.target.value)}>
          <IonSegmentButton value="1">
            <IonLabel>Frühstück</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="2">
            <IonLabel>Mittag</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value="3">
            <IonLabel>Abend</IonLabel>
          </IonSegmentButton>
        </IonSegment>  
        <IonGrid>
          <IonRow>
            <IonCol>
              <IonLabel>Gewicht</IonLabel>
              <IonInput value={gewicht} onIonChange={(e) => setGewicht(e.target.value)}></IonInput>
            </IonCol>
            <IonCol>
              <IonLabel>Größe in cm</IonLabel>
              <IonInput value={groesse} onIonChange={(e) => setGroesse(e.target.value)}></IonInput>
            </IonCol>
            <IonCol>
              <IonLabel color={bmifarbe}>BMI</IonLabel>
              <IonInput color={bmifarbe} disabled value={bmi}></IonInput>
            </IonCol>
          </IonRow>  
        </IonGrid>         
        <IonList>
          {
             speiseplanreturn  && speiseplanreturn
              
          }
        </IonList>
      </IonContent>
    </IonPage>
  )
}
