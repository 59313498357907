import { Preferences } from "@capacitor/preferences";

export const getPreference = ( key: string, callback: Function, handle_null = 'exit' ) => {
  Preferences
    .get( { key: key } )
    .then(
      ( result: any ) =>
        {
          if( result.value === null && handle_null === 'exit' ) return ;
          callback( result.value ) ;
        }
      )
}
