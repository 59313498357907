import { useContext , useState } from "react";
import { DataContext } from "./DataContext";
import { useEffect } from "react";
import axios from 'axios';
import { getPreference } from "../functions/getPreference";

const AppData = () => {
  const {
    appServer,
    appServerChange,
    appHaeuser,
    appHaeuserChange,
    appToken,
    appTokenChange,
    appLoggedIn,
    appLoggedInChange,
    appHaus,
    appStation,
    appStationenChange ,
    appZusatzstoffeChange,
    appStationsbedarfChange,
    appStationsbedarf,
    appStationsbedarfFilterChange,
    appStationsbedarfVorlauf,
    appStationsbedarf1Change,
    appStationsbedarf2Change,
    appStationsbedarf3Change,
    appStationsbedarf4Change,
    appStationsbedarf5Change,
    appStationsbedarf6Change,
    appStationsbedarf7Change,
    appStationsbedarfVorlaufChange,
    appStationsbedarfMinBestellDateChange
  } = useContext(DataContext);

  const [ haeuserFetch , setHaeuserFetch ]= useState( [] ) ;
  const [ serverChecked , setServerChecked ]= useState( false ) ;
  const [ stationenFetch , setStationenFetch ]= useState( [] ) ;
  const [ stationsbedarfFetch , setStationsbedarfFetch ]= useState( [] ) ;

  const checkFetch = ( my_fetch: any ) => {
    if( ! Array.isArray( my_fetch ) ) return false ;
    if( my_fetch.length === 0 ) return false ;
    return true ;
  }

  const fetch_data = (
    backend: string ,
    callback: Function = () => { }
  ) => {
    const config = {
      method: 'post',
      url:
        appServer +
        '/funktionen/api2/senioren/' + backend,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${ appToken }`
      }
    };
    axios( config )
      .then( ( response ) => {
        if ( !response.data ) return console.error( 'FEHLER:', 'Es konnten keine Daten bezogen werden: ' + backend + ' (Code 24okt31-1333)' );
        if ( response.status !== 200 ) return console.error( 'FEHLER:', 'Es konnten keine Daten bezogen werden: ' + backend + ' (Code 24okt31-1334)' );
        if ( !response.data ) return console.error( 'FEHLER:', 'Es konnten keine Daten bezogen werden: ' + backend + ' (Code 24okt31-1337)' );
        if ( callback ) callback( response.data );
      } )
      .catch( () => console.error( 'FEHLER:', 'Es konnten keine Daten bezogen werden: ' + backend + ' (Code 24okt31-1335)' ) );
  };

  useEffect(
    () =>
      {
        if( ! checkFetch( haeuserFetch ) ) return ;
        appHaeuserChange( haeuserFetch ) ;
      } ,
      [ haeuserFetch ]
  ) ;

  useEffect(
    () =>
      {
        if( ! checkFetch( stationenFetch ) ) return ;
        appStationenChange( stationenFetch ) ;
      } ,
      [ stationenFetch ]
  ) ;

  useEffect(
    () =>
      {
        if( ! checkFetch( stationsbedarfFetch ) ) return ;
        appStationsbedarfChange( stationsbedarfFetch ) ;
      } ,
      [ stationsbedarfFetch ]
  ) ;

  useEffect(
    () =>
      {
        let now = Date.now()
        // console.log('now',now)
        let vorlauf_sekunden = appStationsbedarfVorlauf* 60 * 60 * 1000
        // console.log('vorlauf_sekunden', vorlauf_sekunden)

        // console.log('minbestelldate', now+vorlauf_sekunden)

        function padTo2Digits(num:any) {
          return num.toString().padStart(2, '0');
        }
        function formatDate(date:any) {
          return (
            [
              date.getFullYear(),
              padTo2Digits(date.getMonth() + 1),
              padTo2Digits(date.getDate()),
            ].join('-') 
           
          );
        }
        console.log('Date minbestelldate', formatDate(new Date(now+vorlauf_sekunden)))

        appStationsbedarfMinBestellDateChange(formatDate(new Date(now+vorlauf_sekunden)))

      } ,
      [ appStationsbedarfVorlauf ]
  ) ;
  
  useEffect( () => {
    if( !serverChecked ) return ;
    if( !appLoggedIn ) return ;
    if( appToken === '' ) return ;
    fetch_data( 'haeuser.php', setHaeuserFetch ) ;
    fetch_data( 'stationsbedarf.php', setStationsbedarfFetch ) ;
    fetch_data( 'zusatzstoffe.php', appZusatzstoffeChange ) ;
    },[appLoggedIn,serverChecked,appToken])

useEffect( () => {
  if(appLoggedIn===true && appServer && appStation){
    console.log('useEffect appStation fired')
    
    let StationbedarfListe = Array();
    let vorlauf = 24;
    let tag1 = false;
    let tag2 = false;
    let tag3 = false;
    let tag4 = false;
    let tag5 = false;
    let tag6 = false;
    let tag7 = false;
    
      appStationsbedarf.forEach((elementInArray:any) => {
        if(elementInArray.aufgaben_zuordnung_bezug === appStation){
          StationbedarfListe.push(elementInArray)
          vorlauf=elementInArray.aufgaben_zuordnung_vorlauf
          if(elementInArray.aufgaben_zuordnung_1==="1"){
            tag1=true
          }
          if(elementInArray.aufgaben_zuordnung_2==="1"){
            tag2=true
          }
          if(elementInArray.aufgaben_zuordnung_3==="1"){
            tag3=true
          }
          if(elementInArray.aufgaben_zuordnung_4==="1"){
            tag4=true
          }
          if(elementInArray.aufgaben_zuordnung_5==="1"){
            tag5=true
          }
          if(elementInArray.aufgaben_zuordnung_6==="1"){
            tag6=true
          }
          if(elementInArray.aufgaben_zuordnung_7==="1"){
            tag7=true
          }

        }

      })
        
      console.log('StationbedarfListe',StationbedarfListe)
      appStationsbedarfFilterChange(StationbedarfListe)
      console.log('vorlauf',vorlauf)
      appStationsbedarfVorlaufChange(vorlauf)
      appStationsbedarf1Change(tag1)
      appStationsbedarf2Change(tag2)
      appStationsbedarf3Change(tag3)
      appStationsbedarf4Change(tag4)
      appStationsbedarf5Change(tag5)
      appStationsbedarf6Change(tag6)
      appStationsbedarf7Change(tag7)
  }
},[appStation])

const getStationen = () => {
  fetch_data( 'wohnbereiche.php?haus='+appHaus, setStationenFetch ) ;
}

useEffect( () => {
  if(appLoggedIn===true && appHaus &&appServer){

  getStationen();
  }
},[appHaus,appLoggedIn,appServer])

  useEffect(() => {
    const storageHaeuser = async () => {
  
      // await window.localStorage.setItem("senioren.haeuser",JSON.stringify(appHaeuser))
      
      // console.log("store haeuser");
    };

    storageHaeuser();
  
  }, [appHaeuser]);

  useEffect( () => {

    const checkServer = ( server: string ) =>
      {
        if( server !== null ) appServerChange( server ) ;
        setServerChecked( true ) ;
      }

    const checkToken = ( token: string ) =>
      {
        // ! Token-Ablauf checken !
        appLoggedInChange( true );
        appTokenChange( token );
      }

    getPreference( 'senioren.token' , checkToken ) ;
    // getPreference( 'senioren.server' , appServerChange ) ;
    getPreference( 'senioren.server' , checkServer , 'go-on' ) ;

},[])

  return <></>;
};

export default AppData;
